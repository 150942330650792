export default {
  api_test: "http://18.221.175.20/",
  api_prd: "", //为空，则与部署域名相同。
  api_socket: "http://18.221.175.20:9001",
  scraper_backend: "https://api.wander.one",
  express_backend: "https://api.wander.one",
  clerk_key_online: "pk_live_Y2xlcmsud2FuZGVyLm9uZSQ",
  clerk_key_dev: "pk_test_dGhvcm91Z2gtZWZ0LTExLmNsZXJrLmFjY291bnRzLmRldiQ",
  //sendbird_app_id: "3618A127-6C1C-4A33-9BEB-DD8E95703528", // eason's sendbird, REMINDER: change this to production app id!!!
  sendbird_app_id: "36FB48B5-65E8-471B-9D4B-935F9B003C82", // ActEarn's sendbird
  //test_userid: "user#user_2YaweNj36RjSiEBpNpgbJ9BszQc", // piaomz
  test_userid: "user#user_2ZCOSmPf9oypKAZYS4gpb5PTc3G", //wandertest
  onlineDomain: "https://app.wander.one/",
  loadingPrompts: `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAw
L3N2ZyIgd2lkdGg9IjMyMiIgaGVpZ2h0PSIyMTYiPgoJPHJlY3QgeD0iOCIgeT0iOCIgd2lkdGg9
IjMwNiIgaGVpZ2h0PSI2NCIgZmlsbD0idHJhbnNwYXJlbnQiIG9wYWNpdHk9IjAuODAiIHN0cm9r
ZVdpZHRoPSIwIiByeD0iMTIiIHJ5PSIxMiIvPgoJPHJlY3QgeD0iMTYiIHk9IjI0IiB3aWR0aD0i
MjkwIiBoZWlnaHQ9IjEyIiBmaWxsPSIjMTUyOUM3IiByeD0iOCIgcnk9IjgiPgoJCTxhbmltYXRl
IGF0dHJpYnV0ZU5hbWU9ImZpbGwiIHZhbHVlcz0iIzE1MjlDNzsjODc5M0YyOyMxNTI5QzciIGR1
cj0iMnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+Cgk8L3JlY3Q+Cgk8cmVjdCB4PSIxNiIg
eT0iNDQiIHdpZHRoPSIyNDgiIGhlaWdodD0iMTIiIGZpbGw9IiMxNTI5QzciIHJ4PSI4IiByeT0i
OCI+CgkJPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iZmlsbCIgdmFsdWVzPSIjMTUyOUM3OyM4Nzkz
RjI7IzE1MjlDNyIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KCTwvcmVjdD4K
CTxyZWN0IHg9IjgiIHk9Ijk2IiB3aWR0aD0iMzA2IiBoZWlnaHQ9IjY0IiBmaWxsPSJ0cmFuc3Bh
cmVudCIgb3BhY2l0eT0iMC44MCIgIHN0cm9rZVdpZHRoPSIwIiByeD0iMTIiIHJ5PSIxMiIvPgoJ
PHJlY3QgeD0iMTYiIHk9IjExMiIgd2lkdGg9IjI5MCIgaGVpZ2h0PSIxMiIgZmlsbD0iIzE1MjlD
NyIgcng9IjgiIHJ5PSI4Ij4KCQk8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJmaWxsIiB2YWx1ZXM9
IiMxNTI5Qzc7Izg3OTNGMjsjMTUyOUM3IiBkdXI9IjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0
ZSIvPgoJPC9yZWN0PgoJPHJlY3QgeD0iMTYiIHk9IjEzMiIgd2lkdGg9IjI0OCIgaGVpZ2h0PSIx
MiIgZmlsbD0iIzE1MjlDNyIgcng9IjgiIHJ5PSI4Ij4KCQk8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1l
PSJmaWxsIiB2YWx1ZXM9IiMxNTI5Qzc7Izg3OTNGMjsjMTUyOUM3IiBkdXI9IjJzIiByZXBlYXRD
b3VudD0iaW5kZWZpbml0ZSIvPgoJPC9yZWN0Pgo8L3N2Zz4K`,
}
