import { View } from "react-native";
import React from "react";
import Svg, { Mask, Path, G } from "react-native-svg";
export default function DM_svg({ style, isEnabled }) {
  return (
    <View style={style}>
      {isEnabled ? (
        <Svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M14.117.496L1.742 4.62a2.5 2.5 0 00-.106 4.703l4.579 1.75a.883.883 0 01.483.483l1.75 4.579a2.5 2.5 0 004.101.872c.273-.28.48-.615.607-.985l4.125-12.374A2.5 2.5 0 0014.122.49l-.005.006zm1.579 2.628L11.57 15.498a.834.834 0 01-1.562.03l-1.756-4.573a1.657 1.657 0 00-.082-.177l4.06-4.06a.833.833 0 10-1.179-1.178L6.992 9.6a1.667 1.667 0 00-.176-.083L2.243 7.761a.833.833 0 01.03-1.561l12.374-4.125a.833.833 0 011.049 1.049z"
            fill="#fff"
          />
        </Svg>
      ) : (
        <Svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M14.117.546L1.742 4.671a2.5 2.5 0 00-.106 4.702l4.579 1.75a.884.884 0 01.483.484l1.75 4.578a2.5 2.5 0 004.101.872c.273-.278.48-.614.607-.984L17.281 3.7A2.5 2.5 0 0014.122.54l-.005.005zm1.579 2.628L11.57 15.55a.834.834 0 01-1.562.03l-1.756-4.573a1.668 1.668 0 00-.082-.177l4.06-4.06a.833.833 0 10-1.179-1.179l-4.06 4.06a1.667 1.667 0 00-.176-.082L2.243 7.812a.833.833 0 01.03-1.562l12.374-4.124a.833.833 0 011.049 1.049z"
            fill="#5F5F5F"
          />
        </Svg>
      )}
    </View>
  );
}
