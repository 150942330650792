import { View, Image } from "react-native";
import React from "react";

export default function CircleAvatar({ size, uri, style }) {
  return (
    <View
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
          overflow: "hidden",
        },
        style,
      ]}
    >
      <Image
        style={{ width: "100%", height: "100%" }}
        source={{ uri: uri }}
        defaultSource={require("../../../assets/avatar.png")}
      />
    </View>
  );
}
