import { View, Image } from "react-native";
import React from "react";

export default function RoundAvatar({ size, uri, style, radius, defaultSource }) {
  return (
    <View
      style={[
        {
          width: size,
          height: size,
          borderRadius: radius,
          overflow: "hidden",
        },
        style,
      ]}
    >
      <Image
        style={{ width: "100%", height: "100%" }}
        source={{ uri: uri }}
        defaultSource={defaultSource ? defaultSource : require("../../../assets/avatar.png")}
      />
    </View>
  );
}
