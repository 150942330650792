import React from "react";
import {
  Dimensions,
  View,
  Text,
  Image,
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { StatusBar } from "expo-status-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Add_audio_svg from "../../../assets/svg/Add_audio_svg";
import Add_photo_svg from "../../../assets/svg/Add_photo_svg";
import { CircleAvatar } from "@/components";
import { BlurView } from "expo-blur";
import { useUser } from "@clerk/clerk-react";
import { LinearGradient } from "expo-linear-gradient";
//import ExpressTextEditor from "../../components/ExpressTextEditor";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
const ExpressItem = ({ item, createExpress, listLength, index }) => {
  //console.log(item);
  const { user } = useUser();
  const bottomBarHeight = useSafeAreaInsets().bottom + 56;
  //Deperacated Dialog View to Creact Express
  //const [isShowDialog, setIsShowDialog] = useState(false);
  const renderContent = () => {
    return (
      <>
        <View name="answerBox" style={styles.answerBox}>
          <TouchableOpacity style={styles.answerBoxIn} onPress={() => createExpress(item)}>
            <View style={styles.svg}>
              <Add_audio_svg style={{ flex: 1, justifyContent: "center" }} />
              {/* <Add_photo_svg style={{ flex: 1, justifyContent: "center", marginRight: 5 }} /> */}
            </View>
            <View style={styles.answerBoxText}>
              <Text style={{ fontSize: 14, color: "#7F7F7F" }}>Answer to see who resonates...</Text>
            </View>
            <Image style={styles.wanderIcon} source={require("../../../assets/wander.png")} />
          </TouchableOpacity>
          {/* <CircleAvatar size={42} uri={user.imageUrl} style={styles.userIcon} /> */}
        </View>
        <View
          style={{
            zIndex: 3,
            flexDirection: "row",
            alignItems: "flex-start",
            marginHorizontal: 15,
          }}
        >
          <Image style={styles.expressionUserIcon} source={{ uri: item.creator_image_2 }} />
          <View style={{ flex: 1 }}>
            <View name="expressionBoxHidden" style={styles.expressionBoxHidden}>
              <LinearGradient
                name="hiddenWhiteGradient"
                colors={["rgba(255,255,255,0.8)", "rgba(255,255,255,0.3)"]}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  //backgroundColor: "rgba(255,255,255,0.8)",
                }}
              ></LinearGradient>

              <View name="expressionTextView" style={styles.expressionTextView}>
                <Text style={styles.expressionText}>{item.express_answer_locked}</Text>
              </View>
            </View>
            <LinearGradient
              colors={["rgba(255,255,255,0.3)", "transparent"]}
              style={{
                height: 10,
                //marginHorizontal: 15,
                //borderRadius: 10,
                marginBottom: 20,
                //backgroundColor: "rgba(255,255,255,0.8)",
                zIndex: 3,
              }}
            ></LinearGradient>
          </View>
        </View>
        <View
          style={{
            zIndex: 5,
            flexDirection: "row",
            alignItems: "flex-start",
            marginHorizontal: 15,
          }}
        >
          <Image style={styles.expressionUserIcon} source={{ uri: item.creator_image }} />
          <View
            style={{
              backgroundColor: "rgba(255,255,255,0.8)",
              borderRadius: 10,
              marginBottom: 15,
              flex: 1,
            }}
          >
            <Text
              style={{ marginHorizontal: 10, marginVertical: 10 }}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {item.express_answer}
            </Text>
          </View>
        </View>
        {/* <View name="expressionBox" style={styles.expressionBox}>
          <Image style={styles.expressionUserIcon} source={{ uri: item.creator_image }} />
          <View name="expressionTextView" style={styles.expressionTextView}>
            <Text style={styles.expressionText} numberOfLines={2} ellipsizeMode="tail">
              {item.express_answer}
            </Text>
          </View>
        </View> */}
        {/* <View name="expressionBoxWander" style={styles.expressionBoxWander}>
          <View name="expressionTextViewWander" style={styles.expressionTextView}>
            <Text style={styles.expressionTextWander}>{item.wander_word}</Text>
          </View>
        </View> */}

        <View name="wanderAvatar" style={styles.wanderAvatarView}>
          {/* <Image style={styles.wanderIcon} source={require("../../../assets/wander.png")} />
          <Text style={{ color: "white", fontSize: 14 }}>Wander</Text> */}
          <Text style={{ color: "white", fontSize: 14, paddingBottom: 10, marginLeft: 10 }}>
            Fellow Wanderer
          </Text>
        </View>

        <View name="questionView" style={styles.questionView}>
          <Text
            style={{
              color: "white",
              fontSize: 24,
              textAlign: "center",
              fontWeight: 600,
              fontFamily: "Magra-Bold",
            }}
          >
            {item.express_question}
          </Text>
        </View>
        <View name="indexView" style={{ zIndex: 5, marginTop: 16 }}>
          <Text
            style={{
              color: "white",
              fontSize: 16,
              textAlign: "center",
              fontWeight: 600,
              fontFamily: "Magra-Bold",
            }}
          >
            {index + 1 + "/" + listLength}
          </Text>
        </View>
      </>
    );
  };
  const renderContent2 = () => {
    return (
      <View
        style={{
          height: windowHeight,
          width: windowWidth,
          paddingTop: windowWidth * 1.05,
          paddingHorizontal: 15,
          paddingBottom: 15 + bottomBarHeight,
          flexDirection: "column",
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 21,
            fontWeight: 600,
            fontFamily: "Magra-Bold",
            marginBottom: 15,
          }}
        >
          {item.express_question}
        </Text>
        {/* <TouchableOpacity>
          <Text
            style={{
              color: "#AEC1E7",
              fontSize: 16,
              fontFamily: "Magra-Bold",
              marginVertical: 10,
            }}
          >
            {"#placeholder"}
          </Text>
        </TouchableOpacity> */}
        <TouchableOpacity
          style={{
            backgroundColor: "#4B4B4B",
            width: "100%",
            flex: 1,
            borderRadius: 8,
            paddingHorizontal: 12,
            paddingVertical: 18,
          }}
          onPress={() => createExpress(item)}
        >
          <Text
            style={{
              fontFamily: "Lato-Bold",
              fontSize: 14,
              color: "#B6B6B6",
            }}
            numberOfLines={2}
          >
            {'Fellow Wanderer: "' + item.express_answer + '"'}
          </Text>
          <Text
            style={{
              fontFamily: "Lato-Regular",
              fontSize: 14,
              color: "#B6B6B6",
              paddingTop: 15,
            }}
          >
            Answer to see who resonates...
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={{ width: "100%", height: windowHeight }}>
      <StatusBar style="auto" />
      <View style={{ flex: 1, width: "100%", height: "100%" }}>
        <View
          name="backgroundLayer"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
          }}
        >
          <Image
            resizeMode="cover"
            style={{ width: windowWidth * 1.1, height: windowWidth * 1.1, alignSelf: "center" }}
            source={{ uri: item.express_image ? item.express_image : "" }}
            defaultSource={require("../../../assets/wander-bg.png")}
          ></Image>
          <View
            style={{
              width: windowWidth,
              height: "100%",
              position: "absolute",
              flexDirection: "column",
            }}
          >
            <View
              name="dummyView"
              style={{
                width: windowWidth,
                height: windowWidth * 0.5,
              }}
            ></View>
            <LinearGradient
              colors={["transparent", "#383838"]}
              style={{
                width: windowWidth,
                height: windowWidth * 0.6,
              }}
            />
            <View style={{ flex: 1, width: windowWidth, backgroundColor: "#383838" }}></View>
          </View>
        </View>
        {/* <View style={[styles.shadowBackgroundView, { paddingBottom: 12 + bottomBarHeight }]}>
          <TouchableOpacity style={styles.darkLayer} onPress={() => createExpress(item)}>
            <View
              style={{
                width: "100%",
                flexDirection: "column-reverse",
              }}
            >
              <BlurView
                name="blurLayerButtom"
                intensity={30}
                style={[styles.blurLayer, { zIndex: 1 }]}
              ></BlurView>
              <View
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: 10,
                  zIndex: 3,
                  backgroundColor: "rgba(0, 0, 0, 0.42)",
                }}
              ></View>
              <BlurView
                name="blurLayerForHidden"
                intensity={10}
                style={[styles.blurLayer, { zIndex: 4 }]}
              ></BlurView>

              {renderContent()}
              
            </View>
          </TouchableOpacity>
        </View> */}
        {renderContent2()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBackgroundView: {
    backgroundColor: "rgba(51, 51, 51, 0.1)",
    width: "100%",
    height: "100%",
    flexDirection: "column-reverse",
  },
  blurLayer: {
    flex: 1,
    width: "100%",
    height: "100%",
    borderRadius: 10,
    overflow: "hidden",
    position: "absolute",
  },
  darkLayer: {
    //flex: 1,
    marginHorizontal: 12,
    //borderRadius: 10,
    //backgroundColor: "rgba(51, 51, 51, 0.3)",
    flexDirection: "column-reverse",
    //overflow: "hidden",
  },
  answerBox: {
    //height: 42,
    marginHorizontal: 15,
    marginBottom: 20,
    flexDirection: "row",
    zIndex: 5,
    //backgroundColor: "pink",
  },
  answerBoxIn: {
    flex: 1,
    //backgroundColor: "white",
    //marginRight: 8,
    borderColor: "white",
    borderWidth: 2,
    paddingVertical: 7,
    borderRadius: 10,
    flexDirection: "row-reverse",
    justifyContent: "center",
  },
  answerBoxText: {
    flex: 1,
    justifyContent: "center",
    marginLeft: 5,
  },
  svg: {
    height: 30,
    width: 30,
    marginHorizontal: 13,
    alignSelf: "center",
    flexDirection: "row-reverse",
  },
  userIcon: {
    width: 42,
    height: 42,
    borderRadius: 42,
    marginLeft: 8,
  },
  expressionUserIcon: {
    width: 36,
    height: 36,
    borderRadius: 36,
    marginRight: 10,
    zIndex: 0,
    //marginVertical: 13,
  },
  wanderIcon: {
    width: 23,
    height: 23,
    borderRadius: 23,
    marginLeft: 10,
    marginRight: 5,
    marginVertical: 5,
    backgroundColor: "white",
    resizeMode: "cover",
  },
  expressionBox: {
    backgroundColor: "rgba(255,255,255,0.8)",
    marginHorizontal: 15,
    borderRadius: 10,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    zIndex: 5,
  },
  expressionBoxHidden: {
    //backgroundColor: "rgba(255,255,255,0.8)",
    //marginHorizontal: 15,
    //borderRadius: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    marginBottom: 0,
    height: 37,
    //height: 40,
    //flex: 1,
    flexDirection: "row",
    //alignItems: "center",
    overflow: "hidden",
    zIndex: 3,
  },
  expressionTextView: {
    marginHorizontal: 10,
    flex: 1,
  },
  expressionBoxWander: {
    backgroundColor: "rgba(255,255,255,0.8)",
    marginHorizontal: 15,
    borderRadius: 10,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    zIndex: 5,
  },
  expressionText: {
    marginRight: 10,
    marginVertical: 10,
    fontSize: 14,
  },
  expressionTextWander: {
    marginHorizontal: 15,
    marginVertical: 15,
    fontSize: 14,
    flexWrap: "wrap",
  },
  wanderAvatarView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 5,
    zIndex: 5,
  },
  questionView: {
    marginHorizontal: 25,
    marginTop: 8,
    marginBottom: 15,
    //flex: 1,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
  },
});

export default React.memo(ExpressItem);
