import { View, Text, Dimensions, TouchableOpacity, Image, ScrollView } from "react-native";
import React from "react";
import { useState, useEffect } from "react";
import Carousel from "react-native-reanimated-carousel";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { useUser } from "@clerk/clerk-react";
import { upvote } from "../../api";
import CloseBtn_svg from "../../../assets/svg/CloseBtn_svg";
import Heart_svg from "../../../assets/svg/Heart_svg";
import config from "../../config";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
export default function StoryView({ onBack, items, navigation }) {
  const progressValue = useSharedValue(0);
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {!!progressValue && (
        <View
          name="indicator"
          style={{
            width: windowWidth,
            height: 20,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 5,
          }}
        >
          {items.map((item, index) => {
            return (
              <IndicatorItem
                animValue={progressValue}
                index={index}
                key={index}
                length={items.length}
              />
            );
          })}
        </View>
      )}
      <View style={{ flex: 1 }}>
        <Carousel
          loop={false}
          width={windowWidth}
          height={"100%"}
          pagingEnabled={true}
          onProgressChange={(_, absoluteProgress) => (progressValue.value = absoluteProgress)}
          data={items}
          renderItem={({ index, item }) => (
            <StoryItem item={item} index={index} navigation={navigation} />
          )}
        />
      </View>
      <View
        style={{
          position: "absolute",
          marginTop: 20,
          width: "100%",
          flexDirection: "row-reverse",
        }}
      >
        <TouchableOpacity onPress={onBack}>
          <CloseBtn_svg
            style={{ height: 21, width: 21, marginHorizontal: 10, marginVertical: 8 }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

function StoryItem({ item, index, navigation }) {
  //console.log(item);
  const [isUpVoted, setIsUpVoted] = useState(item.upvote_bool);
  const [upVoteNum, setUpVoteNum] = useState(item.upvotes);
  const [allowDM, setAllowDM] = useState(item.upvote_bool);
  const [isRequest, setIsRequest] = useState(false);
  const [isOnDM, setIsOnDM] = useState(false);
  const { user } = useUser();
  const userId = __DEV__ ? config.test_userid : "user#" + user.id;
  useEffect(() => {
    if (isRequest) {
      //console.log("onChange");
      const params = {
        user: userId,
        expression_id: item.expression_pk,
      };
      upvote(params).then(res => {
        console.log("===upvote", res);
        setUpVoteNum(res.upvotes);
        setIsUpVoted(res.upvoters[userId]);
        setAllowDM(res.upvoters[userId]);
      });
      setIsRequest(false);
    }
  }, [isRequest]);
  useEffect(() => {
    if (isOnDM) {
      onDM();
    }
  }, [isOnDM]);
  useEffect(() => {
    if (isOnDM) {
      onDM();
    }
  }, [isOnDM]);
  const onOpenUserDetail = () => {
    if (messageUserId) {
      navigation.push("UserExpressList", { userID: messageUserId, userIcon: icon, userName: name });
    }
  };
  const onDM = async () => {
    if (!allowDM) {
      console.log("not allow DM");
      setIsOnDM(false);
      return;
    }
    console.log(
      "establish chat channel of (current user, user going to chat):",
      userId,
      messageUserId
    );

    try {
      // Assuming you have a way to get or create an sbInstance
      const sbInstance = await getOrCreateSbInstance(userId);
      const newChannel = await createChannelWithUser(sbInstance, userId, messageUserId);
      navigation.navigate("ChatWindow", { channel: newChannel });
    } catch (error) {
      console.error("Error in createChannelWithUser:", error);
    }
    setIsOnDM(false);
  };

  // This function gets or creates a SendBird instance
  const getOrCreateSbInstance = async userId => {
    const APP_ID = config.sendbird_app_id; // Replace with your actual APP_ID
    const currentUserUuid = userId; // Replace with the current user's ID
    const currentUserNickname = userId; // Replace with the current user's nickname

    // Here you either get your existing SendBird instance or create a new one
    let sbInstance = SendBird.getInstance();
    if (!sbInstance) {
      sbInstance = await connectUser(APP_ID, currentUserUuid, currentUserNickname);
    }
    return sbInstance;
  };
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Image style={{ height: "50%" }} defaultSource={require("../../../assets/image.png")} />
      <View
        style={{
          flex: 1,
          width: "auto",
          marginHorizontal: 15,
          marginTop: 20,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <ScrollView>
          <Text
            style={{
              fontFamily: "Lato-Regular",
              fontSize: 20,
              color: "white",
            }}
          >
            {item.comment}
          </Text>
        </ScrollView>
        <View
          style={{
            width: "100%",
            alignSelf: "flex-end",
          }}
        >
          <TouchableOpacity
            style={{ padding: 10, alignSelf: "center", marginBottom: 20, flexDirection: "row" }}
            onPress={() => {
              setIsRequest(true);
            }}
          >
            <Text
              style={{
                color: isUpVoted ? "#F36262" : "white",
                fontSize: 20,
                alignSelf: "center",
                marginRight: 4,
              }}
            >
              {upVoteNum}
            </Text>
            <Heart_svg style={{ height: 30, width: 30 }} isFilled={isUpVoted} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
function IndicatorItem({ animValue, index, length }) {
  const animWidth = windowWidth / length - 6;
  const animStyle = useAnimatedStyle(() => {
    let inputRange = [index - 1, index, index + 1];
    let outputRange = [-animWidth, 0, 0];

    if (index === 0 && animValue?.value > length - 1) {
      inputRange = [length - 1, length, length + 1];
      outputRange = [-animWidth, 0, 0];
    }

    return {
      transform: [
        {
          translateX: interpolate(animValue?.value, inputRange, outputRange, Extrapolate.CLAMP),
        },
      ],
    };
  }, [animValue, index, length]);
  return (
    <View
      style={{
        flex: 1,
        marginHorizontal: 3,
        height: 4,
        backgroundColor: "#686868",
        overflow: "hidden",
        borderRadius: 999,
      }}
    >
      <Animated.View
        style={[
          {
            //flex: 1,
            height: 4,
            width: animWidth,
            backgroundColor: "white",
          },
          animStyle,
        ]}
      ></Animated.View>
    </View>
  );
}
